/*
* Forms
*/

// Reset fieldset
fieldset {
  margin: 0px;
  padding: 0px;
  display: block;
  position: relative;
  width: 100%;
  border: none;
}

fieldset > * {
  width: auto;
}

button,
.submit,
.button {
  font-family: $font-bold;
  display: block;
  margin-top: 30px;
  min-width: 160px;
  padding: 15px 40px 15px 15px;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;

  background: url('images/arrow-btn.svg') right 15px center no-repeat $brand-color-sub;
  background-size: 10px;
  border: none;
  transition: all .17s ease-in-out;

  &:hover {
    box-shadow: 0 10px 5px -7px #999;

  }
}

input {
  padding: 10px;
  max-width: 380px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $border-color;
  border-radius: 0;
}

input:not([type='radio']):not([type='checkbox']) {
  appearance: none;
}

input:not([type='radio']):not([type='checkbox']),
.required-indicator {
  display: block;
  margin-top: 5px;
}

input:focus {
  outline: none;
}

.product-options {
  color: $text-color-sub;
}

form header,
.form-header {
  margin-bottom: 30px;
  max-width: 550px;
}

main form {
  max-width: 550px;
}

label {
  display: block;
  margin-bottom: 25px;
  position: relative;
}

/*
* Radio buttons
*/
input[type='radio'],
input[type='checkbox'] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
  opacity: 0;

  &:checked ~ .radio-ctl {
    background: url('images/check.svg') no-repeat;
    border: none;
  }

  &:checked ~ .checkbox-ctl {
    background: url('images/select-box.svg') 50% no-repeat;
    background-size: 10px;
  }
}

.radio-ctl,
.checkbox-ctl {
  position: absolute;
  top: 3px;
  //transform: translateY(-50%);
  left: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid $border-color;
}

.radio-ctl {
  border-radius: 50%;
}

.ctl-group {
  position: relative;
  display: inline-block;
  padding-left: 25px;
}

/**
* Select lists
**/
.select {
  position: relative;
  display: block;
  margin-top: 5px;

  select {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border-radius: 0;
    padding: 10px 25px 10px 10px;
    width: 135px;
    background: url('images/select-arrow.svg') 111px 50% no-repeat;
    background-size: 10px;
    border: 1px solid $border-color;
  }
}

/**
* Form Validation
*/
.star,
.error-msg {
  color: $brand-color;
}

.error-msg ul {
  list-style-type: none;
  padding-left: 0;
}

.invalid {
  .radio-ctl,
  .checkbox-ctl,
  select,
  input:not(#address_2):not(#shipping_address_2) {
    border-color: $brand-color-sub;
    background-color: rgba($brand-color-sub, .1);
  }
}

/**
* Show/Hide
*/
.desc > * {
  //display: none;
}
.desc,
.edit-info,
.edit-shipping {
  //height: 0;
  max-height: 0;
  overflow: hidden;
  color: $brand-color-sub;
  opacity: 0;
  transition: all .5s ease-out;
}
.expanded {
  height: auto;
  max-height: 500px;
  opacity: 1;
  color: $text-color;
}
.expanded-form {
  height: auto;
  max-height: 1000px;
  opacity: 1;
  color: $text-color;
}
.grower-info .current {
  height: auto;
  max-height: 300px;
  overflow: hidden;
  transition: all .5s ease-out;
}
.grower-info .hidden {
  //height: auto;
  max-height: 0;
  opacity: 0;
  color: $brand-color-sub;
}