/*
step-pages
*/
.step-header {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.step-1 img, .step-2 img {
  display: block;
  margin: 0 auto 5px;
}

.products,
.products {
  text-align: center;
  justify-content: center;
}

.products label {
  margin-bottom: 35px;
  transition: opacity .2s ease-out;
}

.products .fade {
  opacity: .3;
}

.address input:first-of-type {
  margin-bottom: 15px;
}

.back,
.edit-trigger {
  display: block;
  text-decoration: underline;
  margin: 5px 0;
  color: $link-color;
  cursor: pointer;
}

.edit-trigger {
  margin-bottom: 20px;
}

.step-4 {
  hr {
    margin: 35px auto 10px;
  }
  .edit-shipping hr {
    margin-bottom: 30px;
  }
  &.form-header {
    margin-bottom: 40px;
  }
  .grower-info .name {
    font-family: $font-bold;
  }
  .select select {
    background-color: #fff;
    width: 100%;
    max-width: 380px;
    background-position: 95% 50%;
  }
}

.unsubscribed {
  text-align: center;
  min-height: 500px;
  background: none;
  //border: none;
}

.login .error-msg.bottom {
  display: block;
  margin-top: 20px;
}