/*
* Resets
*/

.row {
  margin-left: 0;
  margin-right: 0;
}


/**
* Main css
**/
html {
  font-family: $font-base;
  line-height: $line-height-global;
  font-size: $font-size-base;
  color: $text-color;
}

abbr {
  border-bottom: none !important;
}

header.main {
  background: url('images/header-bg.jpg') 50% 50% no-repeat;
  min-height: 160px;
}

.logos {
  margin: 30px auto;
  width: 200px;
}

section.intro {
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;

  padding: 40px 25px;
}

main {
  background-color: $form-bg-color;
  border: 1px solid $border-color;

  padding: 55px 25px 70px;
}

footer.main {
  min-height: 185px;
  background-color: $footer-color;
  border-top: 8px solid $footer-secondary-color;
  padding: 25px;
  color: $footer-secondary-color;
}

.form-footer {
  display: block;
  text-align: center;
  margin: 25px 0;
  color: $text-color-sub;
}

a:any-link {
  color: $link-color;
  text-decoration: none;

  small &,
  small,
  &.underline {
    text-decoration: underline;
  }
}

.btn {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

.email {
  word-break: normal;
  overflow-wrap: normal;
  cursor: auto;
  text-decoration: underline !important;
}


hr {
  margin: 20px auto;
  border-bottom: 1px solid $border-color;
  border-right: none;
  border-left: none;
  border-top: none;
}

// Small
@media screen and (max-width: 768px) {
  footer.main {
    text-align: center;
  }
}

// Medium Up
@media screen and (min-width: 768px) {
  .logos {
    width: 240px;
    margin-top: 30px;
  }
  header.main {
    min-height: 210px;
  }
  main,
  section.intro {
    padding-left: 100px;
    padding-right: 100px;
  }
  section.intro {
    padding-top: 60px;
  }
  main {
    min-height: 400px;
  }
}

// Large Up
@media screen and (min-width: 1024px) {
  .logos {
    width: 270px;
    margin-top: 40px;
    margin-left: 100px;
  }
  header.main {
    min-height: 260px;
  }
  main {
    min-height: 740px;
  }
}

// Max flexboxgrid wrapper width
@media screen and (max-width: $flexboxgrid-max-width) {
  main,
  section.intro {
    border-left: none;
    border-right: none;
  }
}

// Model windows
.model-window {
  opacity: 0;
  position: fixed;
  top: -100%;
  padding: 35px 25px;
  left: 50%;
  width: 80%;
  text-align: center;
  max-width: 550px;
  z-index: 9999;
  transform: (translateX(-50%));
  transition: all .3s ease-out;
  //display: none;

  button, .button {
    font-size: 12px;
    background-image: none;
    text-align: center;
    padding: 15px 5px;
    border-right: 1px solid #fff;
    color: #fff;
    margin: 10px auto 0;
    width: 100%;
  }
  .btn-container {
    margin: 0 auto;
    max-width: 350px;
  }
}

// Container and show styles
.model-container {

  //.model-bg,
  &:after {
    content: " ";
    background: rgba(0,0,0, .777);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;

    transition: opacity .2s ease-out;
    opacity: 0;
    //display: none;
  }

  // use a anchor links w/ :target or .show w/ js
  &:target,
  &.show {

    //.model-bg,
    &:after {
      opacity: 1;
      top: 0;
      //display: block;
    }

    .model-window {
      opacity: 1;
      top: 20%;
      background: #fff;
      border: 1px solid $border-color;
      box-shadow: 0 3px 7px rgba(0,0,0, .33);
      //display: block;
    }
  }
}


