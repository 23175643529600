/*
* Fonts
*/

@font-face {
  font-family: 'proxima_nova_light';
  src: url('/fonts/proximanovalight-webfont.woff2') format('woff2'),
  url('/fonts/proximanovalight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_semibold';
  src: url('/fonts/proximanovasemibold-webfont.woff2') format('woff2'),
  url('/fonts/proximanovasemibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1, h2 {
  color: $brand-color;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-family: $font-bold;
}

strong {
  font-family: $font-bold;
}