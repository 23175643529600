/**
* Third party deps
*/
@import "../../bower_components/normalize-scss/normalize";


/*
* Flexboxgrid settings
*/
$grid-columns: 12 !default;
$gutter-width: 1rem !default;
$outer-margin: 2rem !default;
$breakpoints:
        sm 425px 425px,
        md 768px 768px,
        lg 1024px 1024px;
$flexboxgrid-max-width: 1170px; //wrapper width

@import "../../bower_components/flexboxgrid-sass/flexboxgrid";


/*
* Sass Vars
**/
$brand-color: #aa4f2a;
$brand-color-sub: #8d340b;
$form-bg-color: #fbfbfb;
$footer-color: #4e4536;
$footer-secondary-color: #786d5d;
$button-color: #8d340b;
$text-color: #4d4d4d;
$text-color-sub: #8f8f8f;
$border-color: #e1e1e1;
$link-color: $brand-color-sub;

$font-base: proxima_nova_light, Arial, Helvetica, SansSerif;
$font-bold: proxima_nova_semibold, Arial, Helvetica, SansSerif;
$line-height-global: 1.5;
$font-size-base: 16px;


/**
* Project files
*/
@import "main";
@import "forms";
@import "fonts";
@import "pages";









