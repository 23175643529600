/**
* Third party deps
*/
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*
* Flexboxgrid settings
*/
.wrapper {
  box-sizing: border-box;
  max-width: 1170px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col-xs-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 8.33333%;
  -moz-flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 16.66667%;
  -moz-flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 33.33333%;
  -moz-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 41.66667%;
  -moz-flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 58.33333%;
  -moz-flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 66.66667%;
  -moz-flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 83.33333%;
  -moz-flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 91.66667%;
  -moz-flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%; }

.col-xs-offset-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%; }

.col-xs-offset-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%; }

.col-xs-offset-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%; }

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 425px) {
  .container {
    width: 425px; }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 768px) {
  .container {
    width: 768px; }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 1024px) {
  .container {
    width: 1024px; }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

/*
* Sass Vars
**/
/**
* Project files
*/
/*
* Resets
*/
.row {
  margin-left: 0;
  margin-right: 0; }

/**
* Main css
**/
html {
  font-family: proxima_nova_light, Arial, Helvetica, SansSerif;
  line-height: 1.5;
  font-size: 16px;
  color: #4d4d4d; }

abbr {
  border-bottom: none !important; }

header.main {
  background: url("images/header-bg.jpg") 50% 50% no-repeat;
  min-height: 160px; }

.logos {
  margin: 30px auto;
  width: 200px; }

section.intro {
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  padding: 40px 25px; }

main {
  background-color: #fbfbfb;
  border: 1px solid #e1e1e1;
  padding: 55px 25px 70px; }

footer.main {
  min-height: 185px;
  background-color: #4e4536;
  border-top: 8px solid #786d5d;
  padding: 25px;
  color: #786d5d; }

.form-footer {
  display: block;
  text-align: center;
  margin: 25px 0;
  color: #8f8f8f; }

a:any-link {
  color: #8d340b;
  text-decoration: none; }
  small a:any-link,
  a:any-link small, a:any-link.underline {
    text-decoration: underline; }

.btn {
  color: #8d340b;
  text-decoration: underline;
  cursor: pointer; }

.email {
  word-break: normal;
  overflow-wrap: normal;
  cursor: auto;
  text-decoration: underline !important; }

hr {
  margin: 20px auto;
  border-bottom: 1px solid #e1e1e1;
  border-right: none;
  border-left: none;
  border-top: none; }

@media screen and (max-width: 768px) {
  footer.main {
    text-align: center; } }

@media screen and (min-width: 768px) {
  .logos {
    width: 240px;
    margin-top: 30px; }
  header.main {
    min-height: 210px; }
  main,
  section.intro {
    padding-left: 100px;
    padding-right: 100px; }
  section.intro {
    padding-top: 60px; }
  main {
    min-height: 400px; } }

@media screen and (min-width: 1024px) {
  .logos {
    width: 270px;
    margin-top: 40px;
    margin-left: 100px; }
  header.main {
    min-height: 260px; }
  main {
    min-height: 740px; } }

@media screen and (max-width: 1170px) {
  main,
  section.intro {
    border-left: none;
    border-right: none; } }

.model-window {
  opacity: 0;
  position: fixed;
  top: -100%;
  padding: 35px 25px;
  left: 50%;
  width: 80%;
  text-align: center;
  max-width: 550px;
  z-index: 9999;
  transform: translateX(-50%);
  transition: all .3s ease-out; }
  .model-window button, .model-window .button {
    font-size: 12px;
    background-image: none;
    text-align: center;
    padding: 15px 5px;
    border-right: 1px solid #fff;
    color: #fff;
    margin: 10px auto 0;
    width: 100%; }
  .model-window .btn-container {
    margin: 0 auto;
    max-width: 350px; }

.model-container:after {
  content: " ";
  background: rgba(0, 0, 0, 0.777);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: opacity .2s ease-out;
  opacity: 0; }

.model-container:target:after, .model-container.show:after {
  opacity: 1;
  top: 0; }

.model-container:target .model-window, .model-container.show .model-window {
  opacity: 1;
  top: 20%;
  background: #fff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.33); }

/*
* Forms
*/
fieldset {
  margin: 0px;
  padding: 0px;
  display: block;
  position: relative;
  width: 100%;
  border: none; }

fieldset > * {
  width: auto; }

button,
.submit,
.button {
  font-family: proxima_nova_semibold, Arial, Helvetica, SansSerif;
  display: block;
  margin-top: 30px;
  min-width: 160px;
  padding: 15px 40px 15px 15px;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  background: url("images/arrow-btn.svg") right 15px center no-repeat #8d340b;
  background-size: 10px;
  border: none;
  transition: all .17s ease-in-out; }
  button:hover,
  .submit:hover,
  .button:hover {
    box-shadow: 0 10px 5px -7px #999; }

input {
  padding: 10px;
  max-width: 380px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  border-radius: 0; }

input:not([type='radio']):not([type='checkbox']) {
  appearance: none; }

input:not([type='radio']):not([type='checkbox']),
.required-indicator {
  display: block;
  margin-top: 5px; }

input:focus {
  outline: none; }

.product-options {
  color: #8f8f8f; }

form header,
.form-header {
  margin-bottom: 30px;
  max-width: 550px; }

main form {
  max-width: 550px; }

label {
  display: block;
  margin-bottom: 25px;
  position: relative; }

/*
* Radio buttons
*/
input[type='radio'],
input[type='checkbox'] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
  opacity: 0; }
  input[type='radio']:checked ~ .radio-ctl,
  input[type='checkbox']:checked ~ .radio-ctl {
    background: url("images/check.svg") no-repeat;
    border: none; }
  input[type='radio']:checked ~ .checkbox-ctl,
  input[type='checkbox']:checked ~ .checkbox-ctl {
    background: url("images/select-box.svg") 50% no-repeat;
    background-size: 10px; }

.radio-ctl,
.checkbox-ctl {
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #e1e1e1; }

.radio-ctl {
  border-radius: 50%; }

.ctl-group {
  position: relative;
  display: inline-block;
  padding-left: 25px; }

/**
* Select lists
**/
.select {
  position: relative;
  display: block;
  margin-top: 5px; }
  .select select {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border-radius: 0;
    padding: 10px 25px 10px 10px;
    width: 135px;
    background: url("images/select-arrow.svg") 111px 50% no-repeat;
    background-size: 10px;
    border: 1px solid #e1e1e1; }

/**
* Form Validation
*/
.star,
.error-msg {
  color: #aa4f2a; }

.error-msg ul {
  list-style-type: none;
  padding-left: 0; }

.invalid .radio-ctl,
.invalid .checkbox-ctl,
.invalid select,
.invalid input:not(#address_2):not(#shipping_address_2) {
  border-color: #8d340b;
  background-color: rgba(141, 52, 11, 0.1); }

/**
* Show/Hide
*/
.desc,
.edit-info,
.edit-shipping {
  max-height: 0;
  overflow: hidden;
  color: #8d340b;
  opacity: 0;
  transition: all .5s ease-out; }

.expanded {
  height: auto;
  max-height: 500px;
  opacity: 1;
  color: #4d4d4d; }

.expanded-form {
  height: auto;
  max-height: 1000px;
  opacity: 1;
  color: #4d4d4d; }

.grower-info .current {
  height: auto;
  max-height: 300px;
  overflow: hidden;
  transition: all .5s ease-out; }

.grower-info .hidden {
  max-height: 0;
  opacity: 0;
  color: #8d340b; }

/*
* Fonts
*/
@font-face {
  font-family: 'proxima_nova_light';
  src: url("/fonts/proximanovalight-webfont.woff2") format("woff2"), url("/fonts/proximanovalight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proxima_nova_semibold';
  src: url("/fonts/proximanovasemibold-webfont.woff2") format("woff2"), url("/fonts/proximanovasemibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

h1, h2 {
  color: #aa4f2a; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-family: proxima_nova_semibold, Arial, Helvetica, SansSerif; }

strong {
  font-family: proxima_nova_semibold, Arial, Helvetica, SansSerif; }

/*
step-pages
*/
.step-header {
  text-transform: uppercase;
  margin-bottom: 15px; }

.step-1 img, .step-2 img {
  display: block;
  margin: 0 auto 5px; }

.products,
.products {
  text-align: center;
  justify-content: center; }

.products label {
  margin-bottom: 35px;
  transition: opacity .2s ease-out; }

.products .fade {
  opacity: .3; }

.address input:first-of-type {
  margin-bottom: 15px; }

.back,
.edit-trigger {
  display: block;
  text-decoration: underline;
  margin: 5px 0;
  color: #8d340b;
  cursor: pointer; }

.edit-trigger {
  margin-bottom: 20px; }

.step-4 hr {
  margin: 35px auto 10px; }

.step-4 .edit-shipping hr {
  margin-bottom: 30px; }

.step-4.form-header {
  margin-bottom: 40px; }

.step-4 .grower-info .name {
  font-family: proxima_nova_semibold, Arial, Helvetica, SansSerif; }

.step-4 .select select {
  background-color: #fff;
  width: 100%;
  max-width: 380px;
  background-position: 95% 50%; }

.unsubscribed {
  text-align: center;
  min-height: 500px;
  background: none; }

.login .error-msg.bottom {
  display: block;
  margin-top: 20px; }
